import { TEXT_STYLES } from '@skyscanner/backpack-web/bpk-component-text';
import BpkText from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';

import STYLES from './CustomSectionHeader.module.scss';

type CustomSectionHeaderProps = {
  header?: string;
  subheader?: string;
};

const CustomSectionHeader = ({
  header = '',
  subheader = '',
}: CustomSectionHeaderProps) => (
  <div className={STYLES.CustomSectionHeader}>
    {header && (
      <span className={STYLES.Heading}>
        <BpkText tagName="h2" textStyle={TEXT_STYLES.hero4}>
          {header}
        </BpkText>
      </span>
    )}
    {subheader && (
      <span className={STYLES.Subheading}>
        <BpkText tagName="p" textStyle={TEXT_STYLES.editorial2}>
          {subheader}
        </BpkText>
      </span>
    )}
  </div>
);

export default CustomSectionHeader;
