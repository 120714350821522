import { useEffect, useState } from 'react';

import classNames from 'classnames';

import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';

import ArticleCard from '../ArticleCard';
import CustomSectionHeader from '../CustomSectionHeader';

import type {
  HighlightedArticlesCardListProps,
  HighlightedArticlesProps,
} from 'common-types/types/HighlightedArticlesProps';

import STYLES from './HighlightedArticles.module.scss';

const HighlightedArticlesCardList = ({
  articles,
  aspectRatio,
}: HighlightedArticlesCardListProps) => (
  <>
    {articles.map((article) => (
      <div className={STYLES.ArticleItem} key={article.articleHref}>
        <ArticleCard
          key={article.articleHref}
          articleCreatedDate={article.articleCreatedDate}
          articleHref={article.articleHref}
          aspectRatio={aspectRatio}
          author={article.author}
          authorHref={article.authorHref}
          fallbackArticleCreatedLocalised={
            article.fallbackArticleCreatedLocalised
          }
          image={article.image}
          title={article.title}
          variant="highlightedArticle"
        />
      </div>
    ))}
  </>
);

const HighlightedArticles = ({
  articles,
  header,
  subheader,
}: HighlightedArticlesProps) => {
  // TEMP FIX: Waiting for Backpack to be fixed here: https://github.com/Skyscanner/backpack/pull/3381
  // Once this is released and Falcon is updated, the following useState and useEffect can be removed.
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  // END TEMP FIX

  return (
    <div className={STYLES.HighlightedArticles}>
      <CustomSectionHeader header={header} subheader={subheader} />

      <BpkBreakpoint
        // TEMP FIX: Waiting for Backpack to be fixed here: https://github.com/Skyscanner/backpack/pull/3381
        // Once this is released and Falcon is updated, the following key can be removed.
        key={isClient ? 'client-key' : 'server-key'}
        // END TEMP FIX
        query={BREAKPOINTS.SMALL_TABLET}
        matchSSR
      >
        {(isMobile: boolean) => {
          if (isMobile) {
            return (
              <div
                className={classNames([
                  STYLES.ArticlesScrollContainer,
                  {
                    [STYLES[`ArticlesScrollContainer--with-margin-top`]]:
                      header || subheader,
                  },
                ])}
              >
                <BpkMobileScrollContainer>
                  <div className={STYLES.ArticlesRow}>
                    <HighlightedArticlesCardList
                      articles={articles}
                      aspectRatio={1.3}
                    />
                  </div>
                </BpkMobileScrollContainer>
              </div>
            );
          }

          return (
            <div
              className={classNames([
                STYLES.ArticlesGrid,
                {
                  [STYLES[`ArticlesGrid--with-margin-top`]]:
                    header || subheader,
                },
              ])}
            >
              <HighlightedArticlesCardList
                articles={articles}
                aspectRatio={2.75}
              />
            </div>
          );
        }}
      </BpkBreakpoint>
    </div>
  );
};

export default HighlightedArticles;
